<template>
  <div>
    <div class="panel panel-default mb-3">
      <div class="panel-heading" :style="'background:' + task.type.custom_color.replace('0.5', '0.1')">
        <h5 class="mb-0">
          <strong class="font-medium">#{{ task.id }}</strong>
          <strong class="margin-l-5 font-medium" style="position: absolute;">
            {{ task.type.name }}
          </strong>

          <span class="label label-default text-white font-medium pull-right"
                :style="'padding: 2px 6px; font-size: 14px; background:' + task.type_status.custom_color.replace('0.25', '1')">
            {{ task.type_status.name }}
          </span>
        </h5>
        <small class="mt-0 font-small">
          {{ task.created_date }} от
          <span class="font-medium" v-if="task.creator">
            {{ task.creator.name }}
          </span>
          <span class="font-medium" v-if="!task.creator && task.client">
            {{ task.client.name }}
          </span>

          <span v-if="task.creator || task.client" class="label font-small-xxs mt-1 pull-right"
                :class="task.creator ? 'label-primary' : ((!task.creator && task.client) ? 'label-success' : 'label-default')">
            {{ task.creator ? 'Сотрудник' : ((!task.creator && task.client) ? 'Клиент' : '') }}
          </span>

          <template v-if="task.tags.length">
            <span v-for="tag in task.tags" class="label font-small-xxs mt-1 pull-right" :class="tag.label_color_class">
              {{ tag.name }}
            </span>
          </template>
        </small>
      </div>

      <div class="panel-body" :style="'background-color: ' + currentStatusColor">
        <div class="row">
          <task-item-comment-block v-if="task.type_id === 1 && task.last_comment"
                                   :task_last_comment="task.last_comment"/>

          <task-item-client-block v-if="task.client"
                                  :task_client="task.client"
                                  :task_id="task.id"
                                  :task_created_at="task.created_at"
                                  :task_type_status_id="task.type_status.id"
                                  :is_called="task.details.is_called ?1:0"/>

          <task-item-car-block v-if="task.car" :task_car="task.car"/>

          <task-item-responsible-block :task_responsible="task.responsible"/>

          <task-item-details-block v-if="Object.keys(task.details).length"
                                   :task_details="task.details"
                                   :with_delimiter="(task.type_id !== 1 && task.last_comment)"
                                   :task_car_price="task.car ? task.car.price : 0"/>

          <task-item-comment-block v-if="task.type_id !== 1 && task.last_comment"
                                   :task_last_comment="task.last_comment" :with_delimiter="false"/>
        </div>
      </div>
      <div class="panel-footer">
        <router-link :to="{ name: 'task.details', params: { taskId: task.id, currentTab: 'История' }}"
                     class="btn btn-success pull-right margin-l-5"
                     title="Детали"
                     type="button">
          <i class="fa fa-info-circle"></i>
        </router-link>

        <button v-if="[1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
                  $store.getters['auth/authUser'].id === task.responsible_id ||
                  $store.getters['auth/authUser'].dayOffCurrentResponsibleID === task.responsible_id"
                class="btn btn-primary pull-right margin-l-5"
                title="Редактировать"
                type="button"
                v-on:click="showChangeTaskModal(task.id)">
          <i class="fa fa-edit"></i>
        </button>

        <button v-if="[1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
                  $store.getters['auth/authUser'].id === task.responsible_id ||
                  $store.getters['auth/authUser'].dayOffCurrentResponsibleID === task.responsible_id"
                class="btn btn-warning pull-right"
                title="В архив"
                type="button"
                v-on:click="removeTask(task.id)">
          <i class="fa fa-trash"></i>
        </button>
      </div>
    </div>

    <modal :adaptive="true"
           :clickToClose="true"
           :draggable="false"
           :name="'change_task_modal_' + task.id"
           :resizable="false"
           :scrollable="false"
           :shiftX="0.5"
           :shiftY="0.5"
           :height="'auto'"
           overlayTransition="fade"
           transition="fade">
      <div class="box box-primary box-modal mb-0">
        <div class="box-header with-border mb-3">
          <h4>#{{ task.id }} {{ task.type.name }}</h4>
        </div>

        <div class="box-body">
          <edit-task-form :task="task" :only_incoming_types="true" :only_basic_type_statuses="true"/>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from "vue";
import VModal from 'vue-js-modal';

Vue.use(VModal);

Vue.component('task-item-client-block', (resolve) => {
  return require(['./../../item_blocks/client-block'], resolve);
});
Vue.component('task-item-car-block', (resolve) => {
  return require(['./../../item_blocks/car-block'], resolve);
});
Vue.component('task-item-term-block', (resolve) => {
  return require(['./../../item_blocks/term-block'], resolve);
});
Vue.component('task-item-responsible-block', (resolve) => {
  return require(['./../../item_blocks/responsible-block'], resolve);
});
Vue.component('task-item-creator-block', (resolve) => {
  return require(['./../../item_blocks/creator-block'], resolve);
});
Vue.component('task-item-details-block', (resolve) => {
  return require(['./../../item_blocks/details-block'], resolve);
});
Vue.component('task-item-comment-block', (resolve) => {
  return require(['./../../item_blocks/comment-block'], resolve);
});

Vue.component('edit-task-form', (resolve) => {
  return require(['./../../details/edit-form'], resolve);
});

export default {
  name: 'task-income-item',

  props: {
    task: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  data: () => ({
    currentStatusColor: '#fff',
  }),

  async created() {
    try {
      if ([10, 11].includes(this.task.type_status_id) && this.task.details && this.task.details.is_called) {
        this.currentStatusColor = this.task.details.is_called === 1 ? 'rgb(104, 193, 124, 0.2)' : 'rgb(255 0 0 / 0.2)';
        return;
      }

      if (
        this.task.type_status_id === 10 &&
        (Math.floor((new Date() - new Date(this.task.created_at)) / (1000 * 60)) <= 30) &&
        (Math.floor((new Date() - new Date(this.task.created_at)) / (1000 * 60)) >= 25)
      ) {
        this.currentStatusColor = 'rgb(255, 211, 81, 0.2)';
        return;
      }
      if (
        this.task.type_status_id === 10 &&
        (Math.floor((new Date() - new Date(this.task.created_at)) / (1000 * 60)) > 30)
      ) {
        this.currentStatusColor = 'rgb(255 0 0 / 0.2)';
        return;
      }

      if (
        this.task.type_status_id === 11 &&
        (Math.floor((new Date() - new Date(this.task.created_at)) / (1000 * 60)) <= 5) &&
        (Math.floor((new Date() - new Date(this.task.created_at)) / (1000 * 60)) >= 3)
      ) {
        this.currentStatusColor = 'rgb(255, 211, 81, 0.2)';
        return;
      }
      if (
        this.task.type_status_id === 11 &&
        (Math.floor((new Date() - new Date(this.task.created_at)) / (1000 * 60)) > 5)
      ) {
        this.currentStatusColor = 'rgb(255 0 0 / 0.2)';
      }
    } catch (error) {
      errorHandler(error);
    }
  },

  methods: {
    async showChangeTaskModal(taskId) {
      this.$modal.show('change_task_modal_' + taskId);
    },
    removeTask(taskId) {
      this.$dialog.confirm('Вы уверены что хотите удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/tasks/' + taskId).then(() => {
          showSuccess();
          this.EventBus.$emit('card-filter-changed');
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  },
}
</script>

<style scoped>
.btn, a {
  white-space: break-spaces;
}
</style>
